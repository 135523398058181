<template>
  <date-range-picker v-model="dateRange" :locale-data="param" :ranges="false">
  </date-range-picker>
</template>

<script>
import DateRangePicker from "vue2-daterange-picker";
//you need to import the CSS manually
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
export default {
  name: "SlotsDemo",
  components: { DateRangePicker },
  data() {
    let startDate = new Date();
    let endDate = new Date();
    startDate.setDate(startDate.getDate() - 6);
    return {
      dateRange: { startDate, endDate },

      param: {
        direction: "ltr",
        format: "dd.mm.yyyy",
        separator: " - ",
        applyLabel: "Сохранить",
        cancelLabel: "Отменить",
        daysOfWeek: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],

        monthNames: [
          "Январь",
          "Февраль",
          "Март",
          "Апрель",
          "Май",
          "Июнь",
          "Июль",
          "Август",
          "Сентябрь",
          "Октябрь",
          "Ноябрь",
          "Декабрь",
        ],
        firstDay: 1,
      },
    };
  },
  watch: {
    dateRange(val) {
      this.$emit("date_range", val);
    },
  },
  filters: {
    dateCell(value) {
      let dt = new Date(value);

      return dt.getDate();
    },
    date(val) {
      return val ? val.toLocaleDateString() : "";
    },
  },
};
</script>

<style>
.slot {
  background-color: #aaa;
  padding: 0.5rem;
  color: #000;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.text-black {
  color: #000;
}
.reportrange-text {
  background: #333 !important;
  border: 1px solid transparent !important;
  border-radius: 5px;
  min-width: 200px;
  text-align: left;
}
.daterangepicker.openscenter {
  background: #333 !important;
}
.daterangepicker .calendar-table {
  background-color: #333 !important;
}
.daterangepicker .calendar-table th,
.daterangepicker .calendar-table td {
  background-color: #333 !important;
}
.daterangepicker .calendar-table .next span,
.daterangepicker .calendar-table .prev span {
  /* border: solid #fff; */
}
.daterangepicker td.in-range {
  background-color: #58909f !important;
}
</style>
