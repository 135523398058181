<template>
  <div class="admin__message__wrapper">
    <div class="message__header">
      <h4>Сообщения</h4>
    </div>
    <div class="message__body">
      <div class="message__list" v-if="message_list">
        <div
          class="message"
          v-for="item in message_list"
          :key="item.message_id"
        >
          <button @click="func_remove(item)">
            <i class="fa fa-times" aria-hidden="true"></i>
          </button>
          <div class="message__content">{{ item.text }}</div>
          <div class="message__info">
            <div class="message__date">
              {{
                new Date(item.create_date).toLocaleDateString() +
                " " +
                new Date(item.create_date).toLocaleTimeString()
              }}
            </div>
          </div>
        </div>
      </div>
      <div class="message__add">
        <form @submit.prevent="func_save">
          <textarea
            required
            cols="30"
            rows="5"
            placeholder="Сообщение..."
            v-model="new_message"
          ></textarea>
          <button>Добавить</button>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      message_list: [],
      new_message: "",
    };
  },
  methods: {
    func_get_message() {
      let url = "/get_admin_message";

      window.sendRequest(url, {}).then((result) => {
        if (!result) return;
        this.message_list = result;
      });
    },
    func_save() {
      let url = "/add_admin_message";

      window.sendRequest(url, { message: this.new_message }).then((result) => {
        if (!result) return;
        this.message_list.unshift(result);
        this.new_message = "";
      });
    },

    func_remove(item) {
      let url = "/remove_admin_message";
      window.sendRequest(url, { message: item.text }).then(() => {
        this.message_list.splice(
          this.message_list.findIndex(
            (x) => Number(x.text) === Number(item.text)
          ),
          1
        );
      });
    },
  },
  mounted() {
    this.func_get_message();
  },
};
</script>

<style></style>
