<template>
  <div class="user__edit__wrapper">
    <div class="close__btn"  @click="$emit('close_user');func_save_info(false)">
      <i class="fa fa-external-link" aria-hidden="true"></i>
    </div>
    <div class="user__edit__body" v-if="user_info">
      <table>
        <tr>
          <td>User ID</td>
          <td>{{ user_info.user_id }}</td>
        </tr>
        <tr>
          <td>Логин</td>
          <td>
            <input
              type="text"
              v-model="user_info.login"
            />
          </td>
        </tr>
        <tr>
          <td>E-mail</td>
          <td>
            <input
              type="text"
              v-model="user_info.email"
            />
          </td>
        </tr>
        <tr>
          <td>Телефон</td>
          <td>
            <input
              type="text"
              v-model="user_info.phone"
            />
          </td>
        </tr>
        <tr>
          <td>Имя</td>
          <td>{{ user_info.name }}</td>
        </tr>
        <tr>
          <td>Дата регистрации</td>
          <td>
            {{
              new Date(user_info.create_date).toLocaleDateString() +
              " " +
              new Date(user_info.create_date).toLocaleTimeString()
            }}
          </td>
        </tr>
        <tr>
          <td>Последняя авторизация</td>
          <td>
            {{
              new Date(user_info.auth_date).toLocaleDateString() +
              " " +
              new Date(user_info.auth_date).toLocaleTimeString()
            }}
          </td>
        </tr>
      </table>

      <div class="user__function">
        <table>
          <tr>
            <td>Адрес</td>
            <td>
              <input
                type="text"
                v-model="user_info.company"

              />
            </td>
          </tr>
          <tr>
            <td>Свитч \ Порт</td>
            <td>
              <input
                type="text"
                v-model="user_info.port"

              />
            </td>
          </tr>
          <tr>
            <td>Цена сутки</td>
            <td>
              <input
                type="text"
                v-model="user_info.price_day"

              />
            </td>
          </tr>
          <tr>
            <td class="balance__table">Баланс</td>
            <td :style="{ color: func_get_color(user_info) }">
              {{ user_info.balance + " руб" }}
            </td>
          </tr>

          <tr>
            <td class="balance__table">Тариф</td>
            <td>
              
                <div class="right__bar">
                  <div class="form__group">
                    <label></label>
                    <textarea rows="3" v-model="user_info.title"></textarea>
                  </div>
                 <!-- <button type="submit" @click="func_save_info(false)">Сохранить</button> -->
                </div>
             
            </td>
          </tr>
        </table>
      </div>

      <div class="user__function">
        <table>
          <tr>
            <td>Работает</td>
            <td>
              <div class="toggle-button-cover">
                <div class="button-cover">
                  <div class="button b2" id="button-12">
                    <input
                      type="checkbox"
                      class="checkbox"
                      v-model="user_info.chat"
      
                    />
                    <div class="knobs">
                      <span></span>
                    </div>
                    <div class="layer"></div>
                  </div>
                </div>
              </div>
            </td>
          </tr>
        </table>
      </div>

      <div class="user__balance__add">
        <form @submit.prevent="func_save_info(true)">
          <input type="number" v-model="new_balance" required />
          <button>Пополнить</button>
        </form>
      </div>
      <FinanceList
        :user_id="Number(user_info.user_id)"
        :refresh_finance="refresh_finance"
      />
    </div>
  </div>
</template>

<script>
import FinanceList from "@/components/finance_list";
export default {
  props: ["id"],
  components: {
    FinanceList,
  },
  data() {
    return {
      user_info: null,
      new_balance: 0,
      refresh_finance: false,
    };
  },
  methods: {
    func_get_data() {
      let url = "/get_user_info";
      window.sendRequest(url, { user_info_id: this.id }).then((result) => {
        console.log(result);
        if (!result) return;
        result.chat = result.chat == 1 ? false : true;
        this.user_info = result;
      });
    },
    func_get_color(item) {
      let balance = Number(item.balance);
      let color = "";
      if (balance <= 0) {
        color = "#c94e4e";
      } else if (balance > 0 && balance < 50) {
        color = "#9f6427";
      } else {
        color = "#217e2d";
      }
      return color;
    },
    func_remove_user() {
      this.$iosConfirm("Удалить пользователя?").then(() => {
        let url = "/remove_user";
        window.sendRequest(url, { id: this.id }).then(() => {
          this.$emit("remove_user");
        });
      });
    },

    func_save_info(balance) {
      let url = "/save_user_info";
      
      if (balance) {
        window
          .sendRequest(url, {
            user_info_id: this.id,
            login: this.user_info.login,
            port: this.user_info.port,
            company: this.user_info.company,
            price_day: this.user_info.price_day,
            title: this.user_info.title,
            phone: this.user_info.phone,
            name: this.user_info.name,
            email: this.user_info.email,
            chat: !this.user_info.chat ? 1 : 0,
            balance: this.new_balance,
          })
          .then(() => {
            this.user_info.balance =
              Number(this.user_info.balance) + Number(this.new_balance);
            this.new_balance = 0;
            this.refresh_finance = true;
            setTimeout(() => {
              this.refresh_finance = false;
              this.$emit("update_balance", this.user_info);
            }, 1000);
          });
      } else {
        window
          .sendRequest(url, {
            user_info_id: this.id,
            login: this.user_info.login,
            port: this.user_info.port,
            company: this.user_info.company,
            price_day: this.user_info.price_day,
            title: this.user_info.title,
            phone: this.user_info.phone,
            name: this.user_info.name,
            email: this.user_info.email,
            chat: !this.user_info.chat ? 1 : 0,
          })
          .then();
      }

      alert(
        'Сохранено'
      );
    },
  },
  mounted() {
    this.func_get_data();
  },
};
</script>

<style></style>
