<template>
  <div class="admin__user__wrapper">
    <div class="user__header">
      <h4>Список пользователей</h4>
    </div>
    <div class="user__body">
      <table>
        <thead>
          <tr>
            <th>ID</th>
            <th></th>
            <th>Баланс</th>
            <th>Логин</th>
            <th>Адрес</th>
            <th>Свитч \ Порт</th>
            <th>Цена сутки</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in users_list" :key="item.user_id">
            <td>{{ item.user_id }}</td>
            <td>
              <button @click="$emit('show_user', item.user_id)">
                <i class="fa fa-user-o" aria-hidden="true"></i>
              </button>
              <span class="demo" v-if="!Number(item.chat)">Отключен</span>
            </td>
            <td :style="{ color: func_get_color(item) }">{{ item.balance }}</td>
            <td>{{ item.login }}</td>
            <td>{{ item.company }}</td>
            <td>{{ item.port }}</td>
            <td>{{ item.price_day }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>




<script>
export default {
  props: {
    update_info: {
      type: Object,
      required: false,
    },
  },
  data() {
    return {
      users_list: [],
    };
  },
  watch: {
    update_info(val) {
      let item = this.users_list.find(
        (x) => Number(x.user_id) === Number(val.user_id)
      );
      item.login = val.login;
      item.email = val.email;
      item.balance = Number(val.balance).toFixed(2);
    },
  },
  methods: {
    func_get_users() {
      let url = "/get_admin_users";

      window.sendRequest(url, {}).then((result) => {
        if (!result) return;
        this.users_list = result;
      });
    },
    func_get_color(item) {
      let balance = Number(item.balance);
      let color = "";
      if (balance <= 0) {
        color = "#c94e4e";
      } else if (balance > 0 && balance < 50) {
        color = "#9f6427";
      } else {
        color = "#217e2d";
      }
      return color;
    },
  },
  mounted() {
    this.func_get_users();
  },
};
</script>

<style></style>
