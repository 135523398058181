<template>
  <div class="admin__finance__wrapper">
    <div class="finance__header">
      <h4>Финансы</h4>
    </div>
    <div class="finance__body">
      <div class="finance__setting">
        <h5>Стоимость подписки</h5>
        <form @submit.prevent="func_save_price">
          <div class="form__group">
            <div class="form__input">
              <input type="number" v-model="price" required />
              <span>руб</span>
            </div>
            <button>Сохранить</button>
          </div>
          <span class="msg" v-if="msg">{{ msg }}</span>
        </form>
      </div>
      <div class="finance__setting">
        <h5>Бонус за регистрацию</h5>
        <form @submit.prevent="func_save_bonus">
          <div class="form__group">
            <div class="form__input">
              <input type="number" v-model="bonus" required />
              <span>руб</span>
            </div>
            <button>Сохранить</button>
          </div>
          <span class="msg" v-if="msg2">{{ msg2 }}</span>
        </form>
      </div>
      <div class="finance__table">
        <div class="filter__content">
          <form @submit.prevent="func_get_data">
            <div class="form__group">
              <label>Поиск по ID</label>
              <div class="form__input">
                <input type="text" v-model="filter.id" placeholder="Найти..." />
              </div>
            </div>
            <div class="form__group date__picker">
              <label>Фильтр по дате</label>
              <datePicker @date_range="func_update_date" />
            </div>
            <button><i class="fa fa-refresh" aria-hidden="true"></i></button>
          </form>
        </div>

        <table class="finance__table_t">
          <thead>
            <tr>
              <th>Дата</th>
              <th>ID</th>
              <th>E-mail</th>
              <th>Тип операции</th>
              <th>Сумма</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in finance_list" :key="item.finance_id">
              <td>
                {{
                  new Date(item.create_date).toLocaleDateString() +
                  " " +
                  new Date(item.create_date).toLocaleTimeString()
                }}
              </td>
              <td>
                {{ item.user_id }}
              </td>
              <td>
                {{ item.email }}
              </td>
              <td :style="{ color: func_get_type(item.type) }">
                {{ item.type }}
              </td>
              <td :style="{ color: func_get_type(item.type) }">
                {{ item.summ }}
              </td>
            </tr>
          </tbody>
        </table>
        <!-- <button @click="func_add_more">Показать еще</button> -->
      </div>
    </div>
  </div>
</template>

<script>
import datePicker from "@/components/datePicker.vue";
export default {
  components: {
    datePicker,
  },
  data() {
    return {
      price: 0,
      bonus: 0,
      finance_list: [],
      msg: "",
      msg2: "",
      filter: {
        id: "",
        date: {
          startDate: new Date(new Date().setDate(new Date().getDate() - 6)),
          endDate: new Date(),
        },
      },
      // limit: 5,
      // offset: 0,
    };
  },
  methods: {
    func_update_date(range) {
      this.filter.date = range;
    },
    func_get_data() {
      let url = "/get_admin_finance";
      window
        .sendRequest(url, {
          filter_id: this.filter.id,
          filter_date_start: this.func_get_string_date(
            this.filter.date.startDate
          ),
          filter_date_end: this.func_get_string_date(this.filter.date.endDate),
          // limit: this.limit,
          // offset: this.offset,
        })
        .then((result) => {
          if (!result) return;
          this.price = result.price ? Number(result.price) : 0;
          this.bonus = result.bonus ? Number(result.bonus) : 0;
          this.finance_list = result.finance;
        });
    },
    func_get_type(value) {
      let color = "";

      if (!value) return "";

      if (value === "Пополнение") {
        color = "#1e6c24";
      } else if (value === "Подписка") {
        color = "#747474";
      } else if (value === "Отказ") {
        color = "#af3e3e";
      } else {
        color = "#d7c22b";
      }
      return color;
    },
    func_get_string_date(val) {
      let date = new Date(val).toLocaleDateString().split(".");
      return date[2] + "-" + date[1] + "-" + date[0];
    },
    func_save_price() {
      let price = this.price;

      if (!price) {
        this.msg = "Укажите цену";
        return;
      }

      if (isNaN(Number(price))) {
        this.msg = "Некорректное число";
        return;
      }

      if (Number(price) <= 0) {
        this.price = 1;
        this.msg = "Число не может быть меньше или равно 0";
        return;
      }
      let url = "/save_admin_price";

      window.sendRequest(url, { price }).then();
    },
    func_save_bonus() {
      let bonus = this.bonus;

      if (!bonus) {
        this.msg2 = "Укажите цену";
        return;
      }

      if (isNaN(Number(bonus))) {
        this.msg2 = "Некорректное число";
        return;
      }

      if (Number(bonus) <= 0) {
        this.bonus = 1;
        this.msg2 = "Число не может быть меньше или равно 0";
        return;
      }
      let url = "/save_admin_bonus";

      window.sendRequest(url, { bonus });
    },
    func_add_more() {
      this.offset++;
      this.func_get_data();
    },
  },
  mounted() {
    this.func_get_data();
  },
};
</script>

<style></style>
